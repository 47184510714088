var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"role-main"},[_c('MyBreadcrumb'),_c('div',{staticClass:"role-main-cont"},[_c('div',{staticClass:"search"},[_c('div',{staticClass:"search-item"},[_c('div',{staticClass:"name"},[_vm._v(" 分类: ")]),_c('div',{staticClass:"cont"},[_c('div',{staticClass:"values"},_vm._l((_vm.categoryList),function(item,fIndex){return _c('div',{key:item.id + 'category',class:_vm.curCategoryId == item.id ? 'active' : '',on:{"click":function($event){return _vm.changeSearch(item)}}},[_vm._v(_vm._s(item.name))])}),0)])])]),_c('el-table',{attrs:{"data":_vm.data,"loading":_vm.loading,"border":"","size":"small","header-cell-style":_vm.headerCellStyle}},[_c('el-table-column',{attrs:{"label":"ID","prop":"data.id","width":"80","fixed":"left"}}),_c('el-table-column',{attrs:{"label":_vm.$t('dataset_userwrong_table_title'),"min-width":"300","show-overflow-tooltip":"","fixed":"left"},scopedSlots:_vm._u([{key:"default",fn:function({row}){return [_c('router-link',{staticStyle:{"color":"#2d8cf0"},attrs:{"to":{name: 'my-detail',
            params: {
              id: row.data.id,
              tab:'cont',
              listId:row.id
            }}}},[_vm._v(" "+_vm._s(row.data.title)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('dataset_userwrong_table_type'),"width":"100"},scopedSlots:_vm._u([{key:"default",fn:function({row}){return [_vm._v(" "+_vm._s(_vm.datatypeObj[row.data.type])+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('dataset_data_table_level'),"width":"100"},scopedSlots:_vm._u([{key:"default",fn:function({row}){return [_vm._v(" "+_vm._s(_vm.datalevelObj[row.data.level])+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('dataset_userwrong_table_created_at'),"min-width":"150"},scopedSlots:_vm._u([{key:"default",fn:function({row}){return [_vm._v(" "+_vm._s(_vm.util.timeFormatter(new Date(+row.created_at * 1000), 'yyyy-MM-dd hh:mm'))+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('dataset_userwrong_table_op'),"width":"150","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function({row}){return [_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.$router.push({
            name:'my-detail',
            params:{
              id: row.data.id,
              tab:'cont',
              listId:row.id
            }})}}},[_vm._v(" "+_vm._s(_vm.$t('system_check'))+" ")]),_c('el-button',{attrs:{"size":"mini","type":"danger"},on:{"click":function($event){return _vm.deleteItem(row)}}},[_vm._v(_vm._s(_vm.$t('system_dele')))])]}}])})],1),_c('div',{staticClass:"page"},[_c('Page',{attrs:{"total":_vm.total,"current":_vm.page,"show-total":"","show-sizer":"","show-elevator":""},on:{"on-change":_vm.changePage,"on-page-size-change":_vm.changePageSize}})],1)],1),_c('DeleModal',{attrs:{"status":_vm.deleModalStatus,"txt":_vm.$t('dataset_userwrong_dele_tip'),"loading":_vm.modalLoading},on:{"cancel":_vm.cancelDele,"confirm":_vm.confirmDele}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }